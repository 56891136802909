
import React from 'react'

const IconsSite = (props) => {

    if(props.name == 'songs'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <rect id={`${props.name}Bg`} x="0" y="0" width="24" height="24" fill="none" />
                <path d="M12.968,20.01l-1.966,0l0,-16.02l1.966,0l0,16.02Zm3.541,-3.031l-2.026,0l0,-9.958l2.026,0l0,9.958Zm-7.021,0l-2.033,0l0,-9.958l2.033,0l0,9.958Zm-3.548,-2.132l-1.95,0l0,-5.694l1.95,0l0,5.694Zm14.07,0l-1.987,0l0,-5.694l1.987,0l0,5.694Z" />
            </svg>
        )
    }
    else if(props.name == 'podcasts'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <rect id={`${props.name}Bg`} x="0" y="0" width="24" height="24" fill="none" />
                <path d="M20.01,19.985l-1.987,0l0,-15.97l1.987,0l0,15.97Zm-14.07,0l-1.95,0l0,-15.97l1.95,0l0,15.97Zm3.548,-2.994l-2.033,0l0,-9.982l2.033,0l0,9.982Zm7.021,0l-2.026,0l0,-9.982l2.026,0l0,9.982Zm-3.541,-1.998l-1.966,0l0,-5.986l1.966,0l0,5.986Z" />
            </svg>
        )
    }
    else if(props.name == 'phone'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />            </svg>
        )
    }
    else if(props.name == 'send'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
            </svg>
        )
    }
    else if(props.name == 'map'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
            </svg>
        )
    }
    else if(props.name == 'videos'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <rect id={`${props.name}Bg`} x="0" y="0" width="24" height="24" fill="none" />
                <path d="M15.175,20.153l-1.405,-1.405l4.978,-4.978l1.405,1.405l-4.978,4.978Zm-4.808,-0.143l-1.433,-1.433l9.643,-9.643l1.433,1.433l-9.643,9.643Zm-5.008,0l-1.39,-1.39l14.651,-14.651l1.39,1.39l-14.651,14.651Zm0.047,-4.969l-1.437,-1.437l9.635,-9.635l1.437,1.437l-9.635,9.635Zm-0.058,-4.959l-1.379,-1.379l4.734,-4.734l1.379,1.379l-4.734,4.734Z" />
            </svg>
        )
    }
    else if(props.name == 'words'){
        return (
            <svg className="iconsSite" id={props.name} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                <rect id={`${props.name}Bg`} x="0" y="0" width="24" height="24" fill="none" />
                <path d="M6.487,12.968l0,-1.966l11.026,0l0,1.966l-11.026,0Z" />
                <path d="M3.99,16.509l0,-2.026l16.02,0l0,2.026l-16.02,0Z" />
                <path d="M3.99,9.488l0,-2.033l16.02,0l0,2.033l-16.02,0Z" />
                <path d="M6.487,5.94l0,-1.95l11.026,0l0,1.95l-11.026,0Z" />
                <path d="M6.487,20.01l0,-1.987l11.026,0l0,1.987l-11.026,0Z" />
            </svg>
        )
    }
    else {
        return 's'
    }
}

export default IconsSite



