import React from 'react'
import Link from 'gatsby-link'
import styles from './_billboard.module.scss'
import Logo from '../logo/logo.js'
import Img from 'gatsby-image'

const Billboard = (props) => (
  <div className={styles.billboardWrapper}>
        <div className={styles.backgroundImage}>
          <Img fluid={props.image}/>
        </div>
        <div className={styles.billboard}>
          <Logo/>
        </div>
  </div>
)

export default Billboard
