import React from 'react'
import Link from 'gatsby-link'
import IconAADesign from '../icons/iconAADesign.svg'
import IconGatsby from '../icons/iconGatsby.svg'
import Social from '../social/social'
import styles from './_footer.module.scss'
import IconsSite from '../icons/iconsSite.js'

const Footer = (props) => (
    <footer className={styles.container}>
        <svg width="100%" viewBox="0 0 1366 160">
            <path d="M0,33.029l0,-33.029l1366,0l0,33.029c-238.02,-49.215 -475.688,-21.606 -713.193,3.121c-211.755,22.047 -441.939,41.616 -652.807,-3.121Z" fill="#fff" />
        </svg>
        <div className={styles.contentWrapper}>
            {/* {props.links && (
                <div className={styles.links}>
                    {props.links.map((node) =>
                        <Link
                        to={`/${node.slug !== null? node.slug: ""}`}
                        key={node.slug}
                        className="btn inverse flat">
                            {node.title}
                        </Link>
                    )}
                </div>
            )} */}
            <div className={styles.power}>
                <a href="https://maps.google.com/?daddr=1043+Center+Drive+Richmond+KY+40475"><address><IconsSite name={'map'}/>1043 Center Drive, Richmond, KY 40475</address></a>
            </div>
            <div className={`author ${styles.copy}`}>
                &copy; {new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format()} 
                <span> {props.author}</span>
            </div>
            {/* <div className={styles.social}>
                <Social />
            </div> */}
  
            <a href="https://www.psychologytoday.com/profile/418459" className="sx-verified-seal"></a>
            
        </div>
    </footer>
)

export default Footer
