import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from "prop-types"

import Helmet from 'react-helmet'
import Navbar from '../components/navbar/navbar.js'
import Footer from '../components/footer/footer.js'
import Billboard from '../components/billboard/billboard.js'
import '../theme/styles.scss';

// class Template extends React.Component {
//   render({data}) {
//     const { location, children } = this.props
//     const rootPath = `${__PATH_PREFIX__}/`
//     const mainLinks = data.allWordpressWpApiMenusMenusItems.edges
    
//     let header
//     // console.log(mainLinks)

//     return (
//       <div className="appWrapper">
//         <Helmet title="Central Baptist Church" />
//         {/* <Navbar links={mainLinks} /> */}
//         <div className="contentWrapper">
//          {/* {mainLinks.slug} */}
//           {children}
//         </div>
//         {/* <Footer links={mainLinks} /> */}
//       </div>
//     )
//   }
// }

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
        <div className="appWrapper">
          <Helmet title="Austin Counseling Services" />
          <Billboard image={data.file.childImageSharp.fluid}/>
          {/* <Navbar links={data.allWordpressWpApiMenusMenusItems.edges[0].node.node} /> */}
          <div className="contentWrapper">
            {children}
          </div>
        <Footer 
           
          author="Austin Counseling Services, LLC" />
        <script type="text/javascript" src="https://member.psychologytoday.com/verified-seal.js" data-badge="12" data-id="418459" data-code="aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s="></script> 
        </div>

      )
    }
  />
)